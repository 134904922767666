<template>
  <div>
    <template v-if="tabs && tabs.length">
      <div class="hidden md:flex md:flex-col">
        <TabGroup
          :model-value="selectedTab"
          class="w-full"
          :tabs="tabs"
          @update:tabs="(tab) => (selectedTab = tab)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <template #default="{ tab }">
            <span v-html="tab.header" />
          </template>
          <!-- eslint-enable vue/no-v-html -->
        </TabGroup>
        <ProductDetailBundledItems
          v-if="selectedTab.isBundleTab && selectedTab.data?.items.length"
          :bundle="selectedTab.data"
          class="px-4 py-8"
        />
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="px-4 py-8 text-lg"
          :class="{
            hidden: tab.header !== selectedTab.header,
            'grid grid-cols-2 gap-8': videos?.length,
          }"
        >
          <Videos
            v-if="index === 0 && videos?.length"
            :videos="videos as GqlVideo[]"
          />
          <!-- eslint-disable vue/no-v-html -->
          <div v-if="tab.content" v-html="tab.content" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>

      <Accordion
        v-for="(tab, index) in tabs"
        :key="index"
        :header="tab.header"
        :class="{ 'border-t': index === 0 }"
        class="border-b px-6 md:hidden"
        :open-immediate="tab.isBundleTab"
        :set-first-tab-open="index === 0"
      >
        <template #header="{ header, isOpen, accordionClick }">
          <button
            :aria-pressed="isOpen"
            :aria-expanded="isOpen"
            class="flex w-full items-center justify-between py-5 font-semibold"
            @click="accordionClick"
          >
            <!-- eslint-disable vue/no-v-html -->
            <h2 class="font-sans" v-html="header" />
            <!-- eslint-enable vue/no-v-html -->
            <DynamicPictogram
              tabindex="0"
              name="chevron"
              :class="isOpen ? 'rotate-90' : '-rotate-90'"
            />
          </button>
        </template>

        <template #default>
          <ProductDetailBundledItems
            v-if="tab.isBundleTab && tab.data?.items.length"
            :bundle="tab.data"
            class="mb-8"
          />
          <div v-else class="mb-8">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="tab.content" />
            <!-- eslint-enable vue/no-v-html -->
            <Videos
              v-if="index === 0 && videos?.length"
              class="mt-6"
              :videos="videos as GqlVideo[]"
            />
          </div>
        </template>
      </Accordion>
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <div v-else class="px-2" v-html="description" />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script setup lang="ts">
import type {
  Product as GqlProduct,
  Video as GqlVideo,
} from '#root/shared/types/graphql-types';
import type { Bundle } from '~/types/bundle-products';

const { t } = useI18n();

interface Tab {
  header?: string;
  content?: string;
  data?: Bundle | null;
  isBundleTab?: boolean;
}

const props = withDefaults(
  defineProps<{
    description: string;
    bundle?: Bundle | null;
    videos: GqlProduct['videos'];
    predefinedTabs?: Tab[];
    lowestPrice: number | string;
  }>(),
  {
    description: '',
    bundle: null,
    videos: () => [],
    predefinedTabs: () => [],
    lowestPrice: '',
  }
);

const descriptionTabs = ref<Tab[]>([]);
const selectedTab = ref<Tab>({
  header: '',
  content: '',
  data: null,
  isBundleTab: false,
});

const tabs: ComputedRef<Tab[]> = computed(() => {
  if (props.bundle) {
    const bundleTab: Tab = {
      header: t('product.included_products'),
      data: props.bundle,
      isBundleTab: true,
    };

    return [bundleTab, ...descriptionTabs.value];
  }
  if (props.predefinedTabs.length) {
    return props.predefinedTabs;
  }
  // Only adds lowest price if is not a bundle
  if (props.lowestPrice) {
    const lowestPriceTab = {
      header: t('product.price_history'),
      content: t('product.lowest_30_days_price', {
        lowestPrice: props.lowestPrice,
      }),
    };

    return [...descriptionTabs.value, lowestPriceTab];
  }

  return descriptionTabs.value;
}) as ComputedRef<Tab[]>;

watch(
  () => props.bundle,
  () => {
    setDefaultSelectedTab();
  }
);

const setDefaultSelectedTab = () => {
  const firstTab = tabs.value?.[0];
  if (firstTab) {
    selectedTab.value = firstTab;
  }
};

if (props.description?.includes('<h1>')) {
  descriptionTabs.value = [
    ...props.description.split('<h1>').reduce((result, h1) => {
      if (h1) {
        const { 0: header, 1: content } = h1.split('</h1>');
        if (header && content) {
          result.push({ header, content } as Tab);
        }
      }

      return result;
    }, [] as Tab[]),
  ];
  setDefaultSelectedTab();
}
</script>
