<template>
  <div class="flex border-b border-black/12">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      class="px-4 pb-2.5 pt-[13px]"
      :class="{
        'font-semibold text-black/87':
          typeof modelValue === 'object' &&
          modelValue.header === (tab as Tab).header,
      }"
      @click="tabClicked(tab)"
    >
      <slot :tab="tab"> {{ tab }} </slot>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Bundle } from '~/types/bundle-products';

interface Tab {
  header?: string;
  content?: string;
  data?: Bundle | null;
  isBundleTab?: boolean;
}

defineProps({
  tabs: {
    type: Array as () => Tab[],
    default: () => [],
  },
  modelValue: {
    type: [String, Object],
    default: '',
  },
});

const emit = defineEmits(['update:tabs']);

const tabClicked = (tab: Tab) => {
  emit('update:tabs', tab);
};
</script>
