<template>
  <div role="status" class="animate-pulse bg-background-extra-light">
    <div class="mx-auto max-w-6xl px-4 md:px-6 md:pb-24 md:pt-6">
      <div class="mb-8 hidden h-4 w-1/2 rounded-full bg-black/6 md:block" />
      <div class="mb-6 flex h-full flex-col md:mb-12 md:flex-row md:gap-12">
        <div class="my-6 flex flex-col items-center md:my-0 md:w-5/12">
          <div class="size-48 rounded-full bg-black/6 md:m-20 md:size-72" />
        </div>
        <div class="flex flex-col py-2 md:w-7/12">
          <div class="mb-auto">
            <div class="mb-2 h-4 w-2/4 rounded-full bg-black/6" />
            <div class="mb-6 h-8 w-5/6 rounded-full bg-black/6 md:h-10" />
            <div class="hidden md:block">
              <div class="mb-2 h-6 w-5/6 rounded-full bg-black/6 md:h-6" />
              <div class="mb-6 h-4 w-3/4 rounded-full bg-black/6 md:h-6" />
            </div>
            <div class="mb-6 h-6 w-1/6 rounded-full bg-black/6 md:h-4" />
          </div>
          <div class="md:flex md:flex-row md:justify-between">
            <div
              class="mb-2 mr-3 hidden h-4 w-2/6 rounded-full bg-black/6 md:block md:h-10"
            />
            <div class="mb-2 h-10 w-full rounded-full bg-black/6" />
          </div>
        </div>
      </div>
      <div class="hidden md:block">
        <div class="mb-8 flex flex-row border-b border-black/6 py-3">
          <div class="mr-4 h-4 w-24 rounded-full bg-black/6" />
          <div class="mr-4 h-4 w-36 rounded-full bg-black/6" />
          <div class="mr-4 h-4 w-32 rounded-full bg-black/6" />
          <div class="mr-4 h-4 w-24 rounded-full bg-black/6" />
        </div>
        <div class="mb-16">
          <div class="mb-4 h-6 w-11/12 rounded-full bg-black/6"></div>
          <div class="mb-4 h-6 w-4/5 rounded-full bg-black/6"></div>
          <div class="mb-10 h-6 w-3/5 rounded-full bg-black/6"></div>

          <div class="mb-4 h-6 w-10/12 rounded-full bg-black/6"></div>
          <div class="h-6 w-2/5 rounded-full bg-black/6"></div>
        </div>
        <div class="flex flex-row">
          <div class="mr-2 h-4 w-12 rounded-full bg-black/6"></div>
          <div class="mr-2 h-4 w-16 rounded-full bg-black/6"></div>
          <div class="mr-2 h-4 w-24 rounded-full bg-black/6"></div>
        </div>
      </div>
      <div>
        <div class="-mx-6 md:hidden">
          <div
            class="flex items-center justify-between border-t border-black/6 px-6 py-5"
          >
            <div class="h-4 w-24 rounded-full bg-black/6"></div>
            <div class="h-4 w-8 rounded-full bg-black/6"></div>
          </div>
          <div
            class="flex items-center justify-between border-t border-black/6 px-6 py-5"
          >
            <div class="h-4 w-36 rounded-full bg-black/6"></div>
            <div class="h-4 w-8 rounded-full bg-black/6"></div>
          </div>
          <div
            class="flex items-center justify-between border-t border-black/6 px-6 py-5"
          >
            <div class="h-4 w-32 rounded-full bg-black/6"></div>
            <div class="h-4 w-8 rounded-full bg-black/6"></div>
          </div>
          <div
            class="flex items-center justify-between border-t border-black/6 px-6 py-5"
          >
            <div class="h-4 w-24 rounded-full bg-black/6"></div>
            <div class="h-4 w-8 rounded-full bg-black/6"></div>
          </div>
        </div>
      </div>
    </div>
    <span class="sr-only">{{ $t('loading') }}</span>
  </div>
</template>
