<template>
  <div>
    <table
      class="w-full table-auto border-separate"
      style="border-spacing: 0 8px"
    >
      <tbody>
        <tr v-for="p in products" :key="p.sku">
          <td>{{ p.quantity }}</td>
          <td class="px-2">x</td>
          <td class="w-full text-black/87">
            <NuxtLink
              :to="
                localePath({
                  name: 'shop-product-slug',
                  params: {
                    slug: p.slug,
                  },
                  query: p.query,
                })
              "
              >{{ p.name }}
              <span class="text-black/54">{{ p.variantLabel }}</span>
            </NuxtLink>
          </td>
          <td class="text-right">
            {{
              $formatPrice(
                p.price.value * p.quantity,
                $i18n.locale as LocaleTerritory
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <DottedDivider class="my-5" />
    <div class="font-semibold">
      <p class="mb-2 flex justify-between text-black/87">
        <span>{{ $t('product.total_value') }}</span>
        <span>
          {{
            $formatPrice(
              props.bundle.unbundledPrice.value,
              $i18n.locale as LocaleTerritory
            )
          }}
        </span>
      </p>
      <p class="flex justify-between text-supporting-dark-4">
        <span>{{ $t('product.you_save') }}</span>
        <span
          >-
          {{
            $formatPrice(
              props.bundle.totalSavings.value,
              $i18n.locale as LocaleTerritory
            )
          }}
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Bundle, BundleItem } from '~/types/bundle-products';

type Option = {
  nameId: string;
  valueId: string;
  value: string;
};

const props = defineProps<{
  bundle: Bundle;
}>();

const localePath = useLocalePath();

const products = computed(() =>
  props.bundle.items.map((bundleItem: BundleItem) => {
    const variantLabel =
      bundleItem.options?.length > 1 ||
      bundleItem.options[0].value.trim().toLowerCase() !==
        bundleItem.name.trim().toLowerCase()
        ? bundleItem.options.map((v) => v.value).join(', ')
        : '';

    const query = {
      options: bundleItem.options
        .map((option: Option) => option.nameId)
        .join(','),
      values: bundleItem.options
        .map((option: Option) => option.valueId)
        .join(','),
    };

    return {
      ...bundleItem,
      variantLabel,
      query,
    };
  })
);

export type { Bundle };
</script>
<style lang="scss" scoped></style>
